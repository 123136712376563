
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
	<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F97d9bb50-3890-426a-be9d-c62b431549b3?alt=media&token=52d8d702-245c-47b7-9a82-78fa106a3776"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F2315a209-202d-42c9-917c-5280ef8769d8?alt=media&token=133afce6-bb43-47c5-ba40-60ef815a090d"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Ff72ec6ec-4232-4e7a-8292-25a732b05058?alt=media&token=a18efbf3-8033-4155-9858-abc4f1f772e6"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Fc92ec348-1a4f-4364-9cf4-a376a8b182b9?alt=media&token=25344915-8fc9-4e7d-af6e-f70f6d99e7fb"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F68020e8d-ded7-447c-8510-a183002312e5?alt=media&token=0f8eda34-93e7-4345-9926-f411558e57ce"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F7ce081e0-56b0-4ed2-b550-27fbeaf356a7?alt=media&token=3b0d4ca1-7793-4107-aeea-decfd47b130e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F8105c050-5af4-4700-8710-404fc14f9003?alt=media&token=cd0a0116-3507-4cdd-aff4-b2c5522a7ca2"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Fc7178d09-79f7-4ba9-9f0e-7a8c370bcd8c?alt=media&token=9a57d5d3-f8c1-44ee-ac3a-f2fdaf2f9701"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F4cbb3a6c-a3bc-4a4b-890c-a356307dc972?alt=media&token=898726d6-d252-401c-ab71-4d709998179c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F5ebbdd22-6f38-465e-a888-51fc5328c661?alt=media&token=ba1f2a08-56bb-41ef-9052-a486051aed3d"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Ff0330d0e-57fc-4dc0-aaa5-6497bc437aae?alt=media&token=fbc18417-84b4-4a3c-8084-687ee9325a57"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F2d51ef07-172f-4936-9ba7-69b774e3c457?alt=media&token=375b91c9-bcdd-4b58-9d07-aa1de357c214"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F4c11a163-37c3-4821-b3f4-7ad5dc2bbcc4?alt=media&token=95c27998-b0ff-482c-a00f-553e880cb84e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F6ccfe6c4-9a5d-442a-aea5-62328226329c?alt=media&token=6aaebc51-15e5-4cc8-a6fc-07b01acd346b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F644f6b61-01bb-4b50-ad82-9ad0f58b6bdc?alt=media&token=bf9523e3-c723-4da2-aaea-f50bea991f65"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F126c20eb-18b3-4a15-8fc3-55dd89519a1d?alt=media&token=1435560f-df6e-4b19-b819-69404cfa2268"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F53532345-af16-4d52-a804-33ef1122f7e7?alt=media&token=97cfaef4-bc10-4301-a4a6-364a5e5fa138"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Fd7fac490-2efe-4fba-937b-d3e81cfa7cda?alt=media&token=19585e22-41f5-4126-a5fb-a3a391acc6fd"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2Fd06d6fdd-3eb7-4f66-9a96-d989f8b5b6ef?alt=media&token=7b88a4ef-ed97-4a89-8fcc-e415def806a7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F9ed72ad7-1a7b-495c-920d-a8f4f52f3459?alt=media&token=5864dbea-5c7f-4d08-8226-c452c058e9f7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F1610155d-6001-4f9e-8e35-6c46ec2f5ae6?alt=media&token=ca99cb31-a381-45ba-b217-4d8f38903d47"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F329b2810-b712-4184-ae8c-b8140e6c27e4?alt=media&token=b5cd0033-f51d-4a5f-91f4-e58776cdd34e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2F64ba27c9-d32b-4f4f-b944-f21375e9bdcf?alt=media&token=1210d5a0-3c73-4fb0-95f1-3f5061a11e9b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU9EN%2FDIVU9ENMAT.pdf?alt=media&token=1abc9017-5780-4593-a353-85f771ed7fff' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
